// custom typefaces
import "typeface-montserrat";
import "typeface-merriweather";

// Highlighting for code blocks
import "prismjs/themes/prism.css";

import "@fontsource/jetbrains-mono";

import "./src/styles/global.css";
import "./src/styles/react-toggle.css";
